import { createSlice } from '@reduxjs/toolkit'
import { AuthMethod } from 'config/constants/auth'
import { WalletType } from 'config/types/wallet'
import { login, logout, removeSuggestedLoginInfo, updateEmail, updateTelegramId, updateWallet } from './action'

export interface AuthState {
  authBy: AuthMethod
  username: string
  uid: number
  wallet?: {
    type: WalletType
    connectorId: string
    name: string

    address?: string
    trackingName?: string
  }
  email: string
  telegramId?: number
}

export const initialState: AuthState = {
  authBy: null,
  username: null,
  uid: null,
  wallet: null,
  email: null,
  telegramId: 0,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(login, (state, { payload }) => {
      const { authBy, username, uid, wallet, email } = payload.payload

      state = {
        authBy,
        username,
        uid,
        wallet,
        email,
        telegramId: 0,
      }

      return state
    })

    builder
      .addCase(updateWallet, (state, { payload }) => {
        const wallet = payload.wallet
        const trackingName = payload.trackingName

        state.wallet = {
          name: wallet.name,
          type: wallet.type,
          connectorId: wallet.adapter.name,
          trackingName,
          ...state.wallet,
        }

        return state
      })
      .addCase(logout, (state) => {
        state = {
          ...initialState,
          email: state.email,
          authBy: state.authBy,
        }

        return state
      })
      .addCase(updateTelegramId, (state, { payload }) => {
        state.telegramId = payload
        return state
      })

      .addCase(removeSuggestedLoginInfo, (state) => {
        state.email = null
        state.authBy = null
        return state
      })
      .addCase(updateEmail, (state, { payload }) => {
        state.email = payload.email
        return state
      })
  },
})

export default authSlice.reducer
