import { ChainIdEnum } from '../network'

const path = '/images/token'

const tokens = {
  MYR: {
    logo: `${path}/myr.png`,
    name: 'MYR',
    code: 'MYR',
    network: ChainIdEnum.FIAT,
    isNative: true,
    decimals: 1,
  },
  THB: {
    logo: `${path}/thb.png`,
    name: 'THB',
    code: 'THB',
    network: ChainIdEnum.FIAT,
    isNative: true,
    decimals: 1,
  },
  IDR: {
    logo: `${path}/idr.png`,
    name: 'IDR',
    code: 'IDR',
    network: ChainIdEnum.FIAT,
    isNative: true,
    decimals: 1,
  },
  PHP: {
    logo: `${path}/php.png`,
    name: 'PHP',
    code: 'PHP',
    network: ChainIdEnum.FIAT,
    isNative: true,
    decimals: 1,
  },
  // INR: {
  //   logo: `${path}/inr.png`,
  //   name: 'INR',
  //   code: 'INR',
  //   network: ChainIdEnum.FIAT,
  //   isNative: true,
  //   decimals: 1,
  // },
}

export const UsableFiatCurrencies = Object.keys(tokens)
export default tokens

