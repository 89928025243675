import { createSlice } from '@reduxjs/toolkit'
import { FetchingStatus } from 'config/constants'
import { CountryEnum } from 'config/constants/country'
import { DepositedTokenInfo, GameTag, ProviderInfo, ScreenTag } from 'config/types'
import { WagerAdventure } from 'config/types/bonus/WagerAdventure'
import { WelcomePackage } from 'config/types/bonus/welcomePackage'
import { FiatCurrencyConversionRateInUSDType } from 'config/types/fiat'
import { Game, GameDetail } from 'config/types/game'
import { MinWithdrawTokenAmounts } from 'config/types/payment'
import { ProfileTierInfo } from 'config/types/profile'
import { PinnedPromotion } from 'config/types/promotion'
import { TokenUsdPriceResponse } from 'services/types'
import {
  initMetadata,
  setAuthModalOpen,
  setCurrencyConversionRateInUSD,
  setInitializeSiteStatus,
  setIsInGame,
  setIsSigned,
  setMenuOpen,
  setPlayingGame,
  setCreditBonusRestrictedGames,
  setSearchOpen,
  updatePopularSearchGames,
  updateTokenUsdPrices,
  updateWagerAdventure,
} from './actions'

export interface AppState {
  clientCountry: CountryEnum
  providers: ProviderInfo[]
  tokenInfoes: DepositedTokenInfo[]
  tokenUsdPrices: TokenUsdPriceResponse
  networkIds: string[]
  tags: GameTag[]
  defaultScreenTags: ScreenTag[]
  serverTimeMargin: number
  serverTime: number
  isMenuOpen: boolean
  isSearchOpen: boolean
  isSigned: boolean
  popularSearchGames: Game[]
  isInGame: boolean
  playingGame: GameDetail
  initialSiteStatus: FetchingStatus
  isAuthModalOpen: boolean
  timeChangeDisplayNameInDays: number

  welcomePackage: WelcomePackage
  pinnedPromotions: PinnedPromotion[]
  tiers: ProfileTierInfo[]
  fiatCurrencyConversionRateInUSD: FiatCurrencyConversionRateInUSDType
  wagerAdventure: WagerAdventure
  minWithdrawTokenAmounts: MinWithdrawTokenAmounts
  creditBonusRestrictedGames: string[]
}

export const initialState: AppState = {
  clientCountry: null,
  providers: [],
  initialSiteStatus: FetchingStatus.Fetching,
  tags: [],
  defaultScreenTags: [],
  serverTimeMargin: 0,
  serverTime: null,
  tokenInfoes: [],
  networkIds: [],
  isMenuOpen: false,
  isSearchOpen: false,
  isSigned: null,
  isAuthModalOpen: false,
  tokenUsdPrices: {},
  popularSearchGames: [],
  isInGame: false,
  playingGame: null,
  timeChangeDisplayNameInDays: null,

  welcomePackage: null,
  pinnedPromotions: [],
  tiers: [],
  fiatCurrencyConversionRateInUSD: {},
  wagerAdventure: null,

  minWithdrawTokenAmounts: {},

  creditBonusRestrictedGames: [],
}

export const appSlice = createSlice({
  name: 'App',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initMetadata, (state, { payload }) => {
        const { data } = payload
        state.clientCountry = data.clientCountry
        state.defaultScreenTags = data.defaultScreenTags || []
        state.providers = data.providers || []
        state.tokenInfoes = data?.tokenInfoes
        state.networkIds = data?.networkIds
        state.tags = data?.tags
        state.serverTime = data?.serverTime
        const serverTimeMargin = new Date().getTime() - (data?.serverTime || 0)
        state.serverTimeMargin = Math.abs(serverTimeMargin) > 10000 ? serverTimeMargin : 0
        state.welcomePackage = data.welcomePackage
        state.pinnedPromotions = data.pinPromotions
        state.tiers = data.tiers
        state.timeChangeDisplayNameInDays = data.timeChangeDisplayNameInDays
        state.minWithdrawTokenAmounts = data.minWithdrawTokenAmounts

        return state
      })

      .addCase(setMenuOpen, (state, { payload }) => {
        const { isOpen } = payload
        state.isMenuOpen = isOpen
        return state
      })

      .addCase(setAuthModalOpen, (state, { payload }) => {
        const { isOpen } = payload
        state.isAuthModalOpen = isOpen
        return state
      })

      .addCase(setSearchOpen, (state, { payload }) => {
        const { isOpen } = payload
        state.isSearchOpen = isOpen
        return state
      })
      .addCase(setIsSigned, (state, { payload }) => {
        const { isSigned } = payload
        state.isSigned = isSigned
        return state
      })

      .addCase(updateTokenUsdPrices, (state, { payload }) => {
        state.tokenUsdPrices = { ...state.tokenUsdPrices, ...payload.data }
        return state
      })

      .addCase(updatePopularSearchGames, (state, { payload }) => {
        state.popularSearchGames = payload.games
        return state
      })
      .addCase(setIsInGame, (state, { payload }) => {
        state.isInGame = payload
        return state
      })
      .addCase(setPlayingGame, (state, { payload }) => {
        state.playingGame = payload.game
        return state
      })
      .addCase(setInitializeSiteStatus, (state, { payload }) => {
        state.initialSiteStatus = payload.status
        return state
      })
      .addCase(updateWagerAdventure, (state, { payload }) => {
        state.wagerAdventure = payload?.wagerAdventure || null
      })
      .addCase(setCurrencyConversionRateInUSD, (state, { payload }) => {
        state.fiatCurrencyConversionRateInUSD = payload.data
        return state
      })
      .addCase(setCreditBonusRestrictedGames, (state, { payload }) => {
        state.creditBonusRestrictedGames = payload.data
        return state
      })
  },
})

export default appSlice.reducer
