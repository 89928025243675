import { createSlice } from '@reduxjs/toolkit'
import { AirdropBox, ReceivedAirdropSummary } from 'config/types'
import { DailyRewardCampaign } from 'config/types/bonus/DailyReward'
import { UserBonus, WageringConditionBonus } from 'config/types/bonus/userBonus'
import {
  resetUserBonus,
  unWatchedNewBonuses,
  updateAirdropBoxStorage,
  updateBonusToActiveWagering,
  updateCurrentWagerInActivatedBonuses,
  updateDailyReward,
  updateReceivedAirdropSummary,
  increaseTodayWagerAmount,
  updateUserBonuses,
  updateUserClaimableHUSDAmount,
  updateUserHUSDBonus,
  updateUserUnlockableHUSDAmount,
} from './actions'

export interface BonusState {
  unwatchedNewBonus: UserBonus
  userBonuses: UserBonus[]

  HUSDBonus: {
    unlockableBalance: string
    claimableBalance: string
    // For HUSD booster bonus
    extraClaimableBalance: string
    airdropBoxStorage: AirdropBox[]
    receivedAirdropSummary: ReceivedAirdropSummary
  }
  dailyReward: DailyRewardCampaign
}

export const initialState: BonusState = {
  unwatchedNewBonus: null,
  userBonuses: [],
  HUSDBonus: {
    unlockableBalance: '0',
    claimableBalance: '0',
    extraClaimableBalance: '0',
    airdropBoxStorage: [],
    receivedAirdropSummary: {
      totalReceivedAirdrop: 0,
      totalAmount: 0,
    },
  },
  dailyReward: null,
}

export const appSlice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserBonuses, (state, { payload }) => {
        const { userBonuses } = payload
        state.userBonuses = userBonuses
        return state
      })
      .addCase(unWatchedNewBonuses, (state, { payload }) => {
        const { unwatchedNewBonuses } = payload
        state.unwatchedNewBonus = unwatchedNewBonuses
        return state
      })
      .addCase(updateCurrentWagerInActivatedBonuses, (state, { payload }) => {
        const { currentWager, id } = payload
        const wageringProcessBonusIndex = state.userBonuses.findIndex(
          (bonus) =>
            bonus instanceof WageringConditionBonus &&
            bonus.isActiveWagering &&
            !bonus.condition.isExpired &&
            bonus.id === id,
        )

        if (wageringProcessBonusIndex >= 0) {
          const wageringProcessBonus = state.userBonuses[wageringProcessBonusIndex] as WageringConditionBonus
          wageringProcessBonus.currentWager = currentWager

          const cloneWageringProcessBonus = Object.assign(
            Object.create(Object.getPrototypeOf(wageringProcessBonus)),
            wageringProcessBonus,
          )
          state.userBonuses[wageringProcessBonusIndex] = cloneWageringProcessBonus
        }

        return state
      })

      .addCase(updateBonusToActiveWagering, (state, { payload }) => {
        const { id } = payload
        const wageringProcessBonusIndex = state.userBonuses.findIndex((bonus) => bonus.id === id)

        if (wageringProcessBonusIndex >= 0) {
          const wageringProcessBonus = state.userBonuses[wageringProcessBonusIndex] as WageringConditionBonus
          wageringProcessBonus.isActiveWagering = true

          const cloneWageringProcessBonus = Object.assign(
            Object.create(Object.getPrototypeOf(wageringProcessBonus)),
            wageringProcessBonus,
          )
          state.userBonuses[wageringProcessBonusIndex] = cloneWageringProcessBonus
        }

        return state
      })
      .addCase(updateUserHUSDBonus, (state, { payload }) => {
        const { claimableBalance, unlockableBalance, extraClaimableBalance } = payload

        state.HUSDBonus = {
          claimableBalance,
          unlockableBalance,
          extraClaimableBalance,
          airdropBoxStorage: state.HUSDBonus.airdropBoxStorage,
          receivedAirdropSummary: state.HUSDBonus.receivedAirdropSummary,
        }

        return state
      })
      .addCase(updateAirdropBoxStorage, (state, { payload }) => {
        const { airdropBoxStorage } = payload

        state.HUSDBonus.airdropBoxStorage = airdropBoxStorage

        return state
      })
      .addCase(updateUserClaimableHUSDAmount, (state, { payload }) => {
        const { claimableBalance, extraClaimableBalance } = payload

        state.HUSDBonus.claimableBalance = claimableBalance
        state.HUSDBonus.extraClaimableBalance = extraClaimableBalance

        return state
      })
      .addCase(updateReceivedAirdropSummary, (state, { payload }) => {
        const { receivedAirdropSummary } = payload

        state.HUSDBonus.receivedAirdropSummary = receivedAirdropSummary

        return state
      })
      .addCase(updateUserUnlockableHUSDAmount, (state, { payload }) => {
        const { unlockableBalance } = payload

        state.HUSDBonus.unlockableBalance = unlockableBalance

        return state
      })

      .addCase(updateDailyReward, (state, { payload }) => {
        const { campaign } = payload

        state.dailyReward = campaign

        return state
      })
      .addCase(increaseTodayWagerAmount, (state, { payload }) => {
        const { amount } = payload

        if (state.dailyReward?.userData && state.dailyReward.userData.todayWagerAmount >= 0) {
          state.dailyReward.userData.todayWagerAmount += amount
        }

        return state
      })

    builder.addCase(resetUserBonus, (state) => {
      state.HUSDBonus = {
        unlockableBalance: '0',
        claimableBalance: '0',
        extraClaimableBalance: '0',
        airdropBoxStorage: [],
        receivedAirdropSummary: {
          totalReceivedAirdrop: 0,
          totalAmount: 0,
        },
      }
      state.userBonuses = []

      return state
    })
  },
})

export default appSlice.reducer
