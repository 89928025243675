import getThemeValue from 'components/util'
import styled, { DefaultTheme, css } from 'styled-components'
import { space, typography, layout } from 'styled-system'
import { TextProps } from './types'

interface ThemedProps extends TextProps {
  theme: DefaultTheme
}

const getColor = ({ color, theme }: ThemedProps) => {
  return getThemeValue(`colors.${color}`, color)(theme)
}

const getFontSize = ({ fontSize, small }: TextProps) => {
  return small ? '14px' : fontSize || '16px'
}

const Text = styled.div<TextProps>`
  color: ${getColor};
  font-size: ${getFontSize};
  font-weight: ${({ bold }) => (bold ? '600' : 'normal')};

  ${({ textTransform }) => textTransform && `text-transform: ${textTransform};`}

  ${({ wordBreak }) =>
    wordBreak &&
    css`
      word-break: ${wordBreak};
    `}

  ${({ whiteSpace }) =>
    whiteSpace &&
    css`
      white-space: ${whiteSpace};
    `}
  
  ${({ ellipsis }) =>
    !!ellipsis &&
    `
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${ellipsis};
    -webkit-box-orient: vertical;
    `}

    ${space} 
    ${typography} 
    ${layout}
`

Text.defaultProps = {
  color: 'text',
  small: false,
}

export default Text
