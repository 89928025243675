import BigNumber from 'bignumber.js'
import { TokenAmount } from '..'

export const timestampOfDay = 86400000

export enum BonusTagEnums {
  CanActive,
  OnlyUse,
  NoDeposit,
}

export enum BonusStatusEnums {
  Available = 1,
  Used = 10,
  Active = -1,
  Invalid = -2,
  Finish = -3,
  Unknown = -999,
}

export enum BonusUserTypeEnums {
  All = 10,
  NewUser = 1,
}

export enum BonusStatusFilterEnums {
  Available = 1,
  History = -1,
}

export enum BonusStatusHistoryEnums {
  Active = 1,
  Available = 2,
  Completed = 3,
  Expired = -1,
  Cancelled = -2,
  Finish = -3,
}

export enum ApiBonusTypeEnums {
  HusdLockReward = 1,
  HusdUnlockBooster = 2,
  IncreaseVIP = 10,
  LuckspinReward = 20,
  DepositBonus = 30,
  CashBonus = 33,
  DepositCashBonus = 34,
  DepositFreeSpinBonus = 31,
  FreeSpinBonus = 32,
  BoosterWagerBonus = 40,
  NoWageringCashBonus = 21,
}

export enum WageringBonusStatusEnums {
  Available,
  WaitingDeposit,
  WaitingSpin,
  InProgressWagering,
  Claimed,
  Finish,
  Cancelled,
  Unknown,
  CreditRewardWaitingRedeem,
}

export interface IBonusCondition {
  userType?: BonusUserTypeEnums
  expiryDate?: number
  updateDate?: number
  maxUserLevel?: number
  minUserLevel?: number

  signUpAfter: number
  minBalanceToRedeem: number
  minAmountWagerToRedeem: number
  minAmountWagerInDay: number
  gameRequiredID?: number
}

export class BonusCondition implements IBonusCondition {
  userType?: BonusUserTypeEnums
  expiryDate?: number
  updateDate?: number
  maxUserLevel?: number
  minUserLevel?: number
  signUpAfter: number
  minBalanceToRedeem: number
  minAmountWagerToRedeem: number
  minAmountWagerInDay: number
  gameRequiredID?: number

  constructor(condition: IBonusCondition) {
    this.userType = condition.userType
    this.expiryDate = condition.expiryDate
    this.updateDate = condition.updateDate
    this.maxUserLevel = condition.maxUserLevel
    this.minUserLevel = condition.minUserLevel

    this.signUpAfter = condition.signUpAfter
    this.minBalanceToRedeem = condition.minBalanceToRedeem
    this.minAmountWagerToRedeem = condition.minAmountWagerToRedeem
    this.minAmountWagerInDay = condition.minAmountWagerInDay
    this.gameRequiredID = condition.gameRequiredID
  }

  public get isExpired() {
    return new Date().getTime() > this.expiryDate
  }

  public validateUserLevelEligibility(level: number) {
    return level >= this.minUserLevel && level <= this.maxUserLevel
  }
}

export interface BonusInfomation {
  image: string
  name?: string
  badge?: string
  activeDate?: number
  instruction?: string
}

export interface ICommonBonus {
  info: BonusInfomation
  condition: IBonusCondition
  tags?: BonusTagEnums[]
  status?: BonusStatusEnums
  type?: ApiBonusTypeEnums
}
export abstract class CommonBonus implements ICommonBonus {
  code: string
  info: BonusInfomation
  condition: BonusCondition
  tags?: BonusTagEnums[]
  status = BonusStatusEnums.Unknown
  type?: ApiBonusTypeEnums

  constructor(bonus: ICommonBonus) {
    this.info = bonus.info
    this.condition = new BonusCondition(bonus.condition)
    this.type = bonus.type
  }
}

// ---------------------- Husd Lock Reward -------------------------- HusdLockReward

export interface IFreeHUSDLockCommonBonus {
  amount: string
}

export interface INoWageringCommonCashBonus {
  bonusAmount: TokenAmount
}

export class FreeHUSDLockCommonBonus extends CommonBonus implements IFreeHUSDLockCommonBonus {
  amount: string
  tags = [BonusTagEnums.OnlyUse]

  constructor(bonus: ICommonBonus, data: IFreeHUSDLockCommonBonus) {
    super(bonus)
    this.amount = data.amount
  }
}

export class NoWageringCommonCashBonus extends CommonBonus implements INoWageringCommonCashBonus {
  bonusAmount: TokenAmount
  tags = [BonusTagEnums.OnlyUse]

  constructor(bonus: ICommonBonus, data: INoWageringCommonCashBonus) {
    super(bonus)
    this.bonusAmount = data.bonusAmount
  }
  tokenAmount: string
}

// ----------------------  HUSD Unlock Booster  -------------------------- HusdUnlockBooster

export interface IHUSDUnLockBoosterCommonBonus {
  duration?: number
  extraUnlockPercent?: number
}

export class HUSDUnLockBoosterCommonBonus extends CommonBonus implements IHUSDUnLockBoosterCommonBonus {
  duration?: number
  extraUnlockPercent?: number
  tags = [BonusTagEnums.CanActive]

  constructor(bonus: ICommonBonus, data: IHUSDUnLockBoosterCommonBonus) {
    super(bonus)
    this.duration = data.duration
    this.extraUnlockPercent = data.extraUnlockPercent
  }
}

// ---------------------- Booster Wager Bonus  -------------------------- BoosterWagerBonus

export interface IBoosterWagerBonus {
  duration?: number
  maxBooster?: number
  boostHourDuration?: number
  boostPercent?: number
  boostedAmount?: number
}

export class BoosterWagerCommonBonus extends CommonBonus implements IBoosterWagerBonus {
  duration?: number
  maxBooster?: number
  boostHourDuration?: number
  boostPercent?: number
  boostedAmount?: number
  tags = [BonusTagEnums.CanActive]

  constructor(bonus: ICommonBonus, data: IBoosterWagerBonus) {
    super(bonus)
    this.duration = data.duration
    this.maxBooster = data.maxBooster
    this.boostHourDuration = data.boostHourDuration
    this.boostPercent = data.boostPercent
    this.boostedAmount = data.boostedAmount
  }
}

// ---------------------- Level Up -------------------------- IncreaseVIP
export interface ILevelUpCommonBonus {
  duration: number
  maxLevels: number
  extraLevels: number
}

export class LevelUpCommonBonus extends CommonBonus implements ILevelUpCommonBonus {
  duration: number
  maxLevels: number
  extraLevels: number
  tags = [BonusTagEnums.CanActive]

  constructor(bonus: ICommonBonus, data: ILevelUpCommonBonus) {
    super(bonus)
    this.extraLevels = data.extraLevels
    this.duration = data.duration
    this.maxLevels = data.maxLevels
  }
}

// ---------------------- Lucky Spin Reward -------------------------- LuckspinReward

export interface IFreeLuckyspinCommonBonus {
  amount: number
}

export class FreeLuckyspinCommonBonus extends CommonBonus {
  amount: number
  tags = [BonusTagEnums.OnlyUse]

  constructor(bonus: ICommonBonus, data: IFreeLuckyspinCommonBonus) {
    super(bonus)
    this.amount = data.amount
  }
}

// ---------------------- Wagering Common Bonus --------------------------
export enum MaxCashoutTypeEnum {
  FixedValue = 1,
  MultiplierBonusValue = 2,
}
export interface IWageringCondition {
  minBet: number
  maxBet: number
  wagerMultiplier: number
  minDeposit?: string
  maxBonusAmountInUsd: string
  bonusAmount?: TokenAmount
  activeDurationInDays: number
  wagerDurationInDays: number
  gameCollectionId: number
  wageringBonusStatus?: WageringBonusStatusEnums
  isCreaditReward: boolean
  maxCashoutInUsd?: string
  maxCashoutType?: MaxCashoutTypeEnum
}

export interface IWageringConditionBigAmount {
  get minDepositBigAmount(): BigNumber
  get maxBonusAmountInUsdBigAmount(): BigNumber
  get maxCashoutInUsdBigAmount(): BigNumber
}

export class WageringConditionCommonBonus
  extends CommonBonus
  implements IWageringConditionBigAmount, IWageringCondition
{
  minBet: number
  maxBet: number
  wagerMultiplier: number
  minDeposit?: string
  maxBonusAmountInUsd: string
  maxCashoutInUsd?: string
  bonusAmount?: TokenAmount
  activeDurationInDays: number
  wagerDurationInDays: number
  gameCollectionId: number
  wageringBonusStatus = WageringBonusStatusEnums.Unknown
  isCreaditReward: boolean

  public get minDepositBigAmount() {
    return new BigNumber(this.minDeposit)
  }

  public get maxBonusAmountInUsdBigAmount() {
    return new BigNumber(this.maxBonusAmountInUsd)
  }

  public get maxCashoutInUsdBigAmount() {
    return new BigNumber(this.maxCashoutInUsd)
  }

  constructor(bonus: ICommonBonus, wageringConditionValues: IWageringCondition) {
    super(bonus)

    this.minBet = wageringConditionValues.minBet
    this.maxBet = wageringConditionValues.maxBet
    this.wagerMultiplier = wageringConditionValues.wagerMultiplier
    this.minDeposit = wageringConditionValues.minDeposit
    this.maxBonusAmountInUsd = wageringConditionValues.maxBonusAmountInUsd
    this.bonusAmount = wageringConditionValues.bonusAmount
    this.activeDurationInDays = wageringConditionValues.activeDurationInDays
    this.wagerDurationInDays = wageringConditionValues.wagerDurationInDays
    this.gameCollectionId = wageringConditionValues.gameCollectionId
    this.isCreaditReward = wageringConditionValues.isCreaditReward
    this.maxCashoutInUsd = wageringConditionValues.maxCashoutInUsd
  }
}

// ---------------------- Deposit Common Bonus -------------------------- DepositBonus
export interface IDepositCommonBonus {
  percent: number
}

export class DepositCommonBonus extends WageringConditionCommonBonus implements IDepositCommonBonus {
  percent: number
  tags = [BonusTagEnums.CanActive]

  constructor(
    bonus: ICommonBonus,
    wageringConditionValues: IWageringCondition,
    depositCommonBonus: IDepositCommonBonus,
  ) {
    super(bonus, wageringConditionValues)
    this.percent = depositCommonBonus.percent
  }
}

export class CashCommonBonus extends WageringConditionCommonBonus {
  minDeposit = '0'
  tags = [BonusTagEnums.NoDeposit, BonusTagEnums.CanActive]
}

export class DepositCashCommonBonus extends WageringConditionCommonBonus {
  minDeposit = '0'
}

// ---------------------- Free Spin Common Bonus -------------------------- FreeSpinBonus
export interface IFreeSpinCommonBonus {
  freeSpinAmount: number
  spinDurationInDays: number
  spinGameIds: number[]
  spinPrice: number
  freeSpinCurrency: string
}

export class DepositFreeSpinCommonBonus extends WageringConditionCommonBonus implements IFreeSpinCommonBonus {
  freeSpinAmount: number
  spinDurationInDays: number
  spinGameIds: number[]
  spinPrice: number
  tags = [BonusTagEnums.CanActive]
  freeSpinCurrency: string

  constructor(
    bonus: ICommonBonus,
    wageringConditionValues: IWageringCondition,
    freeSpinCommonBonus: IFreeSpinCommonBonus,
  ) {
    super(bonus, wageringConditionValues)
    this.freeSpinAmount = freeSpinCommonBonus.freeSpinAmount
    this.spinDurationInDays = freeSpinCommonBonus.spinDurationInDays
    this.spinGameIds = freeSpinCommonBonus.spinGameIds
    this.spinPrice = freeSpinCommonBonus.spinPrice
    this.freeSpinCurrency = freeSpinCommonBonus.freeSpinCurrency
  }
}

// ---------------------- No Deposit Free Spin Common Bonus -------------------------- NoDepositFreeSpinBonus

export class FreeSpinCommonBonus extends DepositFreeSpinCommonBonus {
  tags = [BonusTagEnums.NoDeposit, BonusTagEnums.CanActive]
}
