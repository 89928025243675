import { createAction } from '@reduxjs/toolkit'
import { AirdropBox, ReceivedAirdropSummary } from 'config/types'
import { DailyRewardCampaign } from 'config/types/bonus/DailyReward'
import { UserBonus } from 'config/types/bonus/userBonus'

export const updateUserBonuses = createAction<{ userBonuses: UserBonus[] }>('bonus/updateUserBonuses')
export const unWatchedNewBonuses = createAction<{ unwatchedNewBonuses: UserBonus }>('bonus/unWatchedNewBonuses')
export const updateCurrentWagerInActivatedBonuses = createAction<{ currentWager: string; id: number }>(
  'bonus/updateCurrentWagerInActivatedBonuses',
)
export const updateBonusToActiveWagering = createAction<{ id: number }>('bonus/updateBonusToActiveWagering')

export const updateUserHUSDBonus = createAction<{
  unlockableBalance: string
  claimableBalance: string
  extraClaimableBalance?: string
}>('bonus/updateUserHUSDBonus')

export const updateAirdropBoxStorage = createAction<{
  airdropBoxStorage: AirdropBox[]
}>('bonus/updateAirdropBoxStorage')

export const updateUserClaimableHUSDAmount = createAction<{
  claimableBalance: string
  extraClaimableBalance?: string
}>('bonus/updateUserClaimableHUSDAmount')

export const updateUserUnlockableHUSDAmount = createAction<{
  unlockableBalance: string
}>('bonus/updateUserUnlockableHUSDAmount')

export const updateReceivedAirdropSummary = createAction<{
  receivedAirdropSummary: ReceivedAirdropSummary
}>('bonus/receivedAirdrop')

export const resetUserBonus = createAction('bonus/resetUserBonus')

export const updateDailyReward = createAction<{ campaign: DailyRewardCampaign }>('bonus/updateDailyReward')
export const increaseTodayWagerAmount = createAction<{ amount: number }>('bonus/increaseTodayWagerAmount')
