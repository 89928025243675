import { createAction } from '@reduxjs/toolkit'
import { CountryEnum } from 'config/constants/country'
import { MyTierInfo, UserSettings, WagerInfo } from 'config/types/profile'
import { BalanceResponse, UserData } from 'services/types'

export const initialProfile = createAction<{ data: UserData }>('profile/initialProfile')
export const updateBalances = createAction<{ data: BalanceResponse[] }>('profile/updateBalances')

export const updateUserProfile = createAction<{ displayName: string; avatar: string; canUpdateDisplayNameAt: number }>(
  'profile/updateUserProfile',
)

export const updateUserLinkTelegram = createAction<{ username: string; connected: boolean; id: number }>(
  'profile/updateUserLinkTelegram',
)
export const updateMyTier = createAction<{ data: MyTierInfo }>('profile/updateMyTier')
export const updateMyGameLevelStats = createAction<{ data: WagerInfo }>('profile/upateMyGameLevelStats')
export const updateUserFreeLuckySpin = createAction<{ nextSpinTime: number; amount: number }>(
  'profile/updateUserFreeLuckySpin',
)
export const updateUserWelcomePackageInfo = createAction<{ expiredAt: number; isClaimed: boolean }>(
  'profile/updateUserWelcomePackageInfo',
)
export const updateFavoriteGames = createAction<{ favoriteGames: Record<string, boolean> }>(
  'profile/updateFavoriteGames',
)
export const updateFavoriteGame = createAction<{ gameCode: string; isFavorited: boolean }>('profile/updateFavoriteGame')
export const updateEligibleWageringBonusGames = createAction<{
  eligibleWageringBonusGames: Record<string, boolean>
}>('profile/updateEligibleWageringBonusGames')

export const resetProfile = createAction<{ data: WagerInfo }>('profile/resetProfile')
export const updateUserSetting = createAction<{ settings: Partial<UserSettings> }>('profile/updateUserSetting')
export const updateOnboardingStatus = createAction<{ isOnboarding: boolean }>('profile/updateOnboardingStatus')

export const updateUserRegisteredCountry = createAction<{ registeredCountry: CountryEnum }>(
  'profile/updateUserRegisteredCountry',
)
