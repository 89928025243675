import { colors } from 'theme/colors'
import theme from 'theme'
import { variants, scales } from './types'

export const styleVariants = {
  [variants.PRIMARY]: {
    background: colors.gradient.primaryAlt,
    color: 'text',
  },
  [variants.TEXT]: {
    background: 'transparent',
    color: 'text',

    '&:disabled': {
      background: 'transparent !important',
      borderColor: 'transparent !important',
    },
  },
  [variants.SECONDARY]: {
    backgroundColor: '#1D2533',
    boxShadow: 'none',
    color: 'text',
    ':disabled': {
      backgroundColor: 'transparent',
    },
  },
  [variants.ROUND]: {
    width: '100%',
    border: 0,
    borderRadius: '40px',
    color: 'text',
    background: 'transparent',
  },
  [variants.HIGHLIGHT]: {
    background: colors.buttonBackgroundAlt,
    color: 'text',
  },
  [variants.GRADIENT]: {
    background: colors.gradient.tertiary,
    color: 'text',
  },
  [variants.LIGHT]: {
    background: colors.text,
    color: 'primary',
  },
  [variants.GRAY]: {
    background: '#FFFFFF1F',
    color: 'text',
    border: 0,
    borderRadius: theme.radii.tiny,
  },
  [variants.ERROR]: {
    background: colors.error,
    color: 'text',
  },
}

export const styleScales = {
  [scales.SM]: {
    height: '30px',
  },
  [scales.MD]: {
    height: '46px',
  },
  [scales.LG]: {
    height: '55px',
  },
}
