import BigNumber from 'bignumber.js'
import {
  ApiBonusTypeEnums,
  BonusCondition,
  BonusInfomation,
  BonusStatusEnums,
  BonusTagEnums,
  CommonBonus,
  IBonusCondition,
  IBoosterWagerBonus,
  ICommonBonus,
  IDepositCommonBonus,
  IFreeHUSDLockCommonBonus,
  IFreeLuckyspinCommonBonus,
  IFreeSpinCommonBonus,
  INoWageringCommonCashBonus,
  IHUSDUnLockBoosterCommonBonus,
  ILevelUpCommonBonus,
  IWageringCondition,
  IWageringConditionBigAmount,
  MaxCashoutTypeEnum,
  WageringBonusStatusEnums,
} from '.'
import { Token, TokenAmount } from '..'

export enum ApiBonusStatusEnums {
  WaitApply = 1,
  WaitDeposit = 2,
  WaitFRBResister = 3,
  WaitFRBPlay = 4,
  WaitBetCollect = 5,
  BetCollecting = 6,
  WaitClaim = 7,
  Applied = 10,
  UserCancel = -1,
  Cancel = -2,
  Finish = -3,
  WaitBonusConfirm = 8,
  WaitBonusComplete = 9,
}

export interface IUserBonus extends ICommonBonus {
  info: BonusInfomation
  condition: IBonusCondition
  id: number
  apiStatus?: ApiBonusStatusEnums
  depositId?: number
  redeemDate?: number
  type?: ApiBonusTypeEnums
  bonusUpcoming?: CommonBonus[]
}

export abstract class UserBonus implements IUserBonus {
  id: number
  info: BonusInfomation
  condition: BonusCondition
  depositId?: number
  apiStatus?: ApiBonusStatusEnums
  tags?: BonusTagEnums[]
  status?: BonusStatusEnums
  type?: ApiBonusTypeEnums
  bonusUpcoming?: CommonBonus[]

  constructor(bonus: IUserBonus) {
    this.id = bonus.id
    this.info = bonus.info
    this.condition = new BonusCondition(bonus.condition)
    this.apiStatus = bonus.apiStatus
    this.type = bonus.type
    this.bonusUpcoming = bonus.bonusUpcoming

    this.status =
      bonus.apiStatus === ApiBonusStatusEnums.WaitApply
        ? BonusStatusEnums.Available
        : bonus.apiStatus === ApiBonusStatusEnums.Cancel ||
          bonus.apiStatus === ApiBonusStatusEnums.UserCancel ||
          bonus.apiStatus === ApiBonusStatusEnums.Applied ||
          bonus.apiStatus === ApiBonusStatusEnums.Finish
        ? BonusStatusEnums.Used
        : BonusStatusEnums.Active
  }

  public get IsCancelled() {
    return this.apiStatus === ApiBonusStatusEnums.Cancel || this.apiStatus === ApiBonusStatusEnums.UserCancel
  }
}

export class FreeHUSDLockBonus extends UserBonus implements IFreeHUSDLockCommonBonus {
  readonly tags = [BonusTagEnums.OnlyUse]
  amount: string

  constructor(bonus: IUserBonus, data: IFreeHUSDLockCommonBonus) {
    super(bonus)
    this.amount = data.amount
  }
}

export class NoWageringCashBonus extends UserBonus implements INoWageringCommonCashBonus {
  readonly tags = [BonusTagEnums.OnlyUse]
  bonusAmount: TokenAmount

  constructor(bonus: IUserBonus, data: INoWageringCommonCashBonus) {
    super(bonus)
    this.bonusAmount = data.bonusAmount
  }
}

export class FreeLuckyspinBonus extends UserBonus implements IFreeLuckyspinCommonBonus {
  readonly tags = [BonusTagEnums.OnlyUse]
  amount: number

  constructor(bonus: IUserBonus, data: IFreeLuckyspinCommonBonus) {
    super(bonus)
    this.amount = data.amount
  }
}

export class HUSDUnLockBoosterBonus extends UserBonus implements IHUSDUnLockBoosterCommonBonus {
  readonly tags = [BonusTagEnums.CanActive]

  extraUnlockPercent: number
  duration: number

  constructor(bonus: IUserBonus, data: IHUSDUnLockBoosterCommonBonus) {
    super(bonus)
    this.extraUnlockPercent = data.extraUnlockPercent
    this.duration = data.duration
  }
}

export class BoosterWagerBonus extends UserBonus implements IBoosterWagerBonus {
  readonly tags = [BonusTagEnums.CanActive]

  maxBooster: number
  duration: number
  boostPercent: number
  boostHourDuration: number
  boostedAmount: number

  constructor(bonus: IUserBonus, data: IBoosterWagerBonus) {
    super(bonus)
    this.maxBooster = data.maxBooster
    this.duration = data.duration
    this.boostPercent = data.boostPercent
    this.boostHourDuration = data.boostHourDuration
    this.boostedAmount = data.boostedAmount

    if (bonus.apiStatus === ApiBonusStatusEnums.Applied && !this.condition.isExpired) {
      this.status = BonusStatusEnums.Active
    }
  }
}

export class LevelUpBonus extends UserBonus implements ILevelUpCommonBonus {
  readonly tags = [BonusTagEnums.CanActive]
  extraLevels: number
  maxLevels: number
  duration: number

  constructor(bonus: IUserBonus, data: ILevelUpCommonBonus) {
    super(bonus)
    this.extraLevels = data.extraLevels
    this.duration = data.duration
    this.maxLevels = data.maxLevels

    if (bonus.apiStatus === ApiBonusStatusEnums.Applied && !this.condition.isExpired) {
      this.status = BonusStatusEnums.Active
    }
  }
}

export interface ICashoutMilestone {
  code: string
  bonusPercentage: number
  bonusAmount: BigNumber
  wagerPercentage: number
  wagerAmount: BigNumber
  isClaimed?: boolean
  isReached?: boolean
  token: Token
}
export interface IUserWageringCashoutMilestone {
  milestones?: ICashoutMilestone[]
  milestoneClaimToken?: Token
  isActiveMilestone?: boolean
}

export interface IUserWageringCondition extends IWageringCondition, IUserWageringCashoutMilestone {
  currentWager?: string
  targetWager?: string
  cashoutAmount?: TokenAmount
}

export class WageringConditionBonus extends UserBonus implements IUserWageringCondition, IWageringConditionBigAmount {
  readonly tags = [BonusTagEnums.CanActive]
  bonusAmount?: TokenAmount
  minBet: number
  maxBet: number
  wagerMultiplier: number
  minDeposit: string
  maxBonusAmountInUsd: string
  wageringBonusStatus: WageringBonusStatusEnums
  currentWager?: string
  targetWager?: string
  activeDurationInDays: number
  wagerDurationInDays: number
  gameCollectionId: number
  isActiveWagering?: boolean
  isCreaditReward: boolean
  maxCashoutInUsd?: string
  cashoutAmount?: TokenAmount
  milestones?: ICashoutMilestone[]
  milestoneClaimToken?: Token
  isActiveMilestone?: boolean
  maxCashoutType?: MaxCashoutTypeEnum

  public get currentWagerBigAmount() {
    const currentWagerBigAmount = new BigNumber(this.currentWager)
    return currentWagerBigAmount.gt(this.targetWagerBigAmount) ? this.targetWagerBigAmount : currentWagerBigAmount
  }

  public get targetWagerBigAmount() {
    return new BigNumber(this.targetWager)
  }

  public get maxCashoutInUsdBigAmount() {
    return new BigNumber(this.maxCashoutInUsd)
  }

  public get progress() {
    if (this.targetWagerBigAmount.eq(0)) return 100
    return Math.floor(this.currentWagerBigAmount.div(this.targetWagerBigAmount).multipliedBy(10000).toNumber()) / 100
  }

  public get reachedMilestoneDetails() {
    return this.milestones.map((item) => {
      return { ...item, isReached: this.progress >= item.wagerPercentage }
    })
  }

  public get minDepositBigAmount() {
    return new BigNumber(this.minDeposit)
  }

  public get maxBonusAmountInUsdBigAmount() {
    return new BigNumber(this.maxBonusAmountInUsd)
  }

  constructor(bonus: IUserBonus, wageringConditionValues: IUserWageringCondition) {
    super(bonus)

    this.minBet = wageringConditionValues.minBet
    this.maxBet = wageringConditionValues.maxBet
    this.wagerMultiplier = wageringConditionValues.wagerMultiplier
    this.minDeposit = wageringConditionValues.minDeposit
    this.maxBonusAmountInUsd = wageringConditionValues.maxBonusAmountInUsd
    this.bonusAmount = wageringConditionValues.bonusAmount
    this.currentWager = wageringConditionValues.currentWager
    this.targetWager = wageringConditionValues.targetWager
    this.activeDurationInDays = wageringConditionValues.activeDurationInDays
    this.wagerDurationInDays = wageringConditionValues.wagerDurationInDays
    this.gameCollectionId = wageringConditionValues.gameCollectionId
    this.isCreaditReward = wageringConditionValues.isCreaditReward
    this.maxCashoutInUsd = wageringConditionValues.maxCashoutInUsd
    this.maxCashoutType = wageringConditionValues.maxCashoutType
    this.milestones = wageringConditionValues.milestones
    this.milestoneClaimToken = wageringConditionValues.milestoneClaimToken
    this.isActiveMilestone = wageringConditionValues.isActiveMilestone
    this.isActiveWagering = false
    this.cashoutAmount = wageringConditionValues.cashoutAmount

    switch (bonus.apiStatus) {
      case ApiBonusStatusEnums.WaitBonusComplete:
      case ApiBonusStatusEnums.BetCollecting:
        this.wageringBonusStatus = WageringBonusStatusEnums.InProgressWagering
        this.isActiveWagering = true
        break

      case ApiBonusStatusEnums.WaitBetCollect:
        this.wageringBonusStatus = WageringBonusStatusEnums.InProgressWagering
        break

      case ApiBonusStatusEnums.WaitApply:
        this.wageringBonusStatus = WageringBonusStatusEnums.Available
        break

      case ApiBonusStatusEnums.WaitDeposit:
        this.wageringBonusStatus = WageringBonusStatusEnums.WaitingDeposit
        break

      case ApiBonusStatusEnums.WaitFRBResister:
        this.wageringBonusStatus = WageringBonusStatusEnums.Available
        break

      case ApiBonusStatusEnums.WaitClaim:
        if (!wageringConditionValues.isCreaditReward) {
          this.wageringBonusStatus = WageringBonusStatusEnums.InProgressWagering
          this.isActiveWagering = true
        } else {
          this.wageringBonusStatus = WageringBonusStatusEnums.CreditRewardWaitingRedeem
          this.status = BonusStatusEnums.Available
        }
        break

      case ApiBonusStatusEnums.WaitFRBPlay:
        this.wageringBonusStatus = WageringBonusStatusEnums.WaitingSpin
        break

      case ApiBonusStatusEnums.Applied:
        this.wageringBonusStatus = WageringBonusStatusEnums.Claimed
        break

      case ApiBonusStatusEnums.Finish:
        this.wageringBonusStatus = WageringBonusStatusEnums.Finish
        break

      default:
        this.wageringBonusStatus = WageringBonusStatusEnums.Cancelled
        break
    }
  }
}

export class DepositBonus extends WageringConditionBonus implements IDepositCommonBonus {
  percent: number

  constructor(bonus: IUserBonus, wageringConditionValues: IUserWageringCondition, data: IDepositCommonBonus) {
    super(bonus, wageringConditionValues)
    this.percent = data.percent
    this.depositId = bonus.depositId

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitDeposit) {
      this.status = BonusStatusEnums.Available
    }
  }
}

export class CashBonus extends WageringConditionBonus {
  readonly tags = [BonusTagEnums.CanActive, BonusTagEnums.NoDeposit]

  constructor(bonus: IUserBonus, wageringConditionValues: IUserWageringCondition) {
    super(bonus, wageringConditionValues)

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitBonusConfirm) {
      this.status = BonusStatusEnums.Available
    }
  }
}

export class DepositCashBonus extends WageringConditionBonus {
  constructor(bonus: IUserBonus, wageringConditionValues: IUserWageringCondition) {
    super(bonus, wageringConditionValues)

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitDeposit) {
      this.status = BonusStatusEnums.Available
    }
  }
}

export class DepositFreespinBonus extends WageringConditionBonus implements IFreeSpinCommonBonus {
  freeSpinAmount: number
  spinPrice: number
  spinDurationInDays: number
  spinGameIds: number[]
  freeSpinCurrency: string

  constructor(
    bonus: IUserBonus,
    wageringConditionValues: IUserWageringCondition,
    freeSpinBonusValue: IFreeSpinCommonBonus,
  ) {
    super(bonus, wageringConditionValues)
    this.freeSpinAmount = freeSpinBonusValue.freeSpinAmount
    this.spinPrice = freeSpinBonusValue.spinPrice
    this.spinDurationInDays = freeSpinBonusValue.spinDurationInDays
    this.spinGameIds = freeSpinBonusValue.spinGameIds
    this.freeSpinCurrency = freeSpinBonusValue.freeSpinCurrency

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitDeposit) {
      this.status = BonusStatusEnums.Available
      this.wageringBonusStatus = WageringBonusStatusEnums.WaitingDeposit
    }

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitClaim && wageringConditionValues.isCreaditReward) {
      this.status = BonusStatusEnums.Active
      this.wageringBonusStatus = WageringBonusStatusEnums.CreditRewardWaitingRedeem
    }

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitFRBResister) {
      this.status = BonusStatusEnums.Active
      this.wageringBonusStatus = WageringBonusStatusEnums.WaitingSpin
    }
  }

  public get progress() {
    if (
      this.targetWagerBigAmount.eq(0) &&
      this.currentWagerBigAmount.eq(0) &&
      this.wageringBonusStatus === WageringBonusStatusEnums.Cancelled
    )
      return 0
    return super.progress
  }
}

export class FreespinBonus extends DepositFreespinBonus {
  readonly tags = [BonusTagEnums.CanActive, BonusTagEnums.NoDeposit]

  constructor(
    bonus: IUserBonus,
    wageringConditionValues: IUserWageringCondition,
    freeSpinBonusValue: IFreeSpinCommonBonus,
  ) {
    super(bonus, wageringConditionValues, freeSpinBonusValue)

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitFRBResister) {
      this.status = BonusStatusEnums.Available
      this.wageringBonusStatus = WageringBonusStatusEnums.Available
    }

    if (bonus.apiStatus === ApiBonusStatusEnums.WaitClaim && wageringConditionValues.isCreaditReward) {
      this.status = BonusStatusEnums.Active
      this.wageringBonusStatus = WageringBonusStatusEnums.CreditRewardWaitingRedeem
    }
  }
}
