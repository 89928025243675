import { createAction } from '@reduxjs/toolkit'
import { LoginPayload } from 'config/types/auth'
import { WalletInfo } from 'config/types/wallet'

export const login = createAction<{
  payload: LoginPayload
}>('auth/login')

export const updateEmail = createAction<{ email: string }>('auth/updateEmail')
export const updateWallet = createAction<{ wallet: WalletInfo; trackingName?: string }>('auth/updateWallet')
export const logout = createAction('auth/logout')
export const removeSuggestedLoginInfo = createAction('auth/removeSuggestedLoginInfo')

export const updateTelegramId = createAction<number>('auth/updateTelegramId')
