import { createSlice } from '@reduxjs/toolkit'
import { CountryEnum } from 'config/constants/country'
import { FeaturedGamesMap } from 'config/types/game'
import { LuckySpinStatusProps } from 'config/types/luckyspin'
import { ProfileTierInfo, UserSettings, WagerInfo } from 'config/types/profile'
import { getBalanceTokenKey } from 'utils'
import {
  initialProfile,
  resetProfile,
  updateBalances,
  updateEligibleWageringBonusGames,
  updateFavoriteGame,
  updateFavoriteGames,
  updateMyGameLevelStats,
  updateMyTier,
  updateOnboardingStatus,
  updateUserFreeLuckySpin,
  updateUserLinkTelegram,
  updateUserProfile,
  updateUserRegisteredCountry,
  updateUserSetting,
  updateUserWelcomePackageInfo,
} from './actions'

export interface ProfileState {
  displayName: string
  avatar: string
  userCode: string
  balances: { [tokenKey: string]: string }
  tier: ProfileTierInfo
  wager: WagerInfo
  luckySpin: LuckySpinStatusProps
  welcomePackExpiredAt: number
  hasClaimedWelcomePackage: boolean
  featuredGames: FeaturedGamesMap
  canUpdateDisplayNameAt: number
  userSettings: UserSettings
  registeredDate: Date
  registeredCountry: CountryEnum
  isOnboarding: boolean
  telegram: {
    connected: boolean
    username: string
    id: number
  }
}

export const initialProfileState: ProfileState = {
  displayName: '',
  avatar: '',
  userCode: '',
  balances: {},
  tier: null,
  hasClaimedWelcomePackage: false,
  wager: {
    percentage: 0,
    target: 0,
    score: 0,
    totalBet: 0,
  },
  luckySpin: {
    nextSpinTime: 0,
    amount: 0,
  },
  telegram: {
    connected: false,
    username: '',
    id: 0,
  },
  welcomePackExpiredAt: 0,
  userSettings: {},
  featuredGames: {
    wageringBonusGames: {},
    favoriteGames: {},
  },
  canUpdateDisplayNameAt: 0,
  registeredDate: null,
  registeredCountry: null,
  isOnboarding: false,
}

export const profileSlice = createSlice({
  name: 'Profile',
  initialState: initialProfileState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initialProfile, (state, { payload }) => {
        const { balances, profile, welcomePackage } = payload.data

        state.tier = profile.tier
        state.wager = profile.wager
        state.displayName = profile.displayName
        state.canUpdateDisplayNameAt = profile.canUpdateDisplayNameAt
        state.avatar = profile.avatar
        state.userCode = profile.userCode
        state.registeredDate = profile.registeredDate
        state.telegram = profile.telegram

        state.welcomePackExpiredAt = welcomePackage.expiredAt
        state.hasClaimedWelcomePackage = welcomePackage.isClaimed

        balances.forEach((item) => {
          const tokenName = getBalanceTokenKey(item.network, item.currency)
          state.balances[tokenName] = item.amount
        })
        return state
      })
      .addCase(updateUserRegisteredCountry, (state, { payload }) => {
        const { registeredCountry } = payload
        state.registeredCountry = registeredCountry
        return state
      })
    builder.addCase(updateOnboardingStatus, (state, { payload }) => {
      const { isOnboarding } = payload

      state.isOnboarding = isOnboarding
      return state
    })
    builder.addCase(updateBalances, (state, { payload }) => {
      const { data } = payload

      data.forEach((item) => {
        const tokenName = getBalanceTokenKey(item.network, item.currency)
        state.balances[tokenName] = item.amount
      })
      return state
    })

    builder.addCase(updateUserProfile, (state, { payload }) => {
      state.displayName = payload.displayName
      state.avatar = payload.avatar
      state.canUpdateDisplayNameAt = payload.canUpdateDisplayNameAt

      return state
    })

    builder.addCase(updateUserLinkTelegram, (state, { payload }) => {
      state.telegram.username = payload.username
      state.telegram.connected = payload.connected
      state.telegram.id = payload.id

      return state
    })

    builder.addCase(updateMyTier, (state, { payload }) => {
      const { data } = payload

      state.tier = data.tier
      return state
    })
    builder.addCase(updateMyGameLevelStats, (state, { payload }) => {
      const { data } = payload

      state.wager = data
      return state
    })
    builder.addCase(updateUserWelcomePackageInfo, (state, { payload }) => {
      const { expiredAt, isClaimed } = payload

      state.welcomePackExpiredAt = expiredAt
      state.hasClaimedWelcomePackage = isClaimed
      return state
    })
    builder.addCase(updateEligibleWageringBonusGames, (state, { payload }) => {
      const { eligibleWageringBonusGames } = payload
      state.featuredGames.wageringBonusGames = eligibleWageringBonusGames
      return state
    })
    builder.addCase(updateUserFreeLuckySpin, (state, { payload }) => {
      const { nextSpinTime, amount } = payload

      if (!state.luckySpin) {
        state.luckySpin = initialProfileState.luckySpin
      }

      state.luckySpin.amount = amount
      state.luckySpin.nextSpinTime = nextSpinTime
      return state
    })
    builder.addCase(updateFavoriteGames, (state, { payload }) => {
      const { favoriteGames } = payload

      state.featuredGames.favoriteGames = favoriteGames
      return state
    })
    builder.addCase(updateFavoriteGame, (state, { payload }) => {
      const { gameCode, isFavorited } = payload

      state.featuredGames.favoriteGames[gameCode] = isFavorited
      return state
    })
    builder.addCase(resetProfile, (state) => {
      state.balances = {}
      state.tier = null
      state.userSettings = {}
      state.wager = {
        percentage: 0,
        target: 0,
        score: 0,
        totalBet: 0,
      }
      state.luckySpin.amount = 0
      state.luckySpin.nextSpinTime = 0
      state.welcomePackExpiredAt = 0

      return state
    })
    builder.addCase(updateUserSetting, (state, { payload }) => {
      const { settings } = payload
      state.userSettings = {
        ...state.userSettings,
        ...settings,
      }
    })
  },
})

export default profileSlice.reducer
