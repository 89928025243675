export const LANGUAGES = [
  {
    logo: 'EnglishFlagIcon',
    code: 'en',
    betby: 'en',
    name: 'English',
    IETFCode: 'en',
    ISO: 'en',
  },
  {
    logo: 'ArabFlagIcon',
    code: 'ar',
    betby: 'ar',
    name: 'العربية',
    IETFCode: 'ar',
    ISO: 'ar',
  },
  {
    logo: 'BrazilFlagIcon',
    code: 'pt-BR',
    betby: 'pt-br',
    name: 'Português (Brasil)',
    IETFCode: 'pt-BR',
    ISO: 'pt-BR',
  },
  {
    logo: 'ChinaFlagIcon',
    code: 'zh-Hans',
    betby: 'zh',
    name: '简体中文',
    IETFCode: 'zh-CN',
    ISO: 'zh-Hans',
  },
  {
    logo: 'HongkongFlagIcon',
    code: 'zh-Hant',
    betby: 'zh',
    IETFCode: 'zh-TW',
    name: '繁體中文',
    ISO: 'zh-Hant',
  },
  {
    logo: 'FranceFlagIcon',
    code: 'fr',
    betby: 'fr',
    name: 'Français',
    IETFCode: 'fr',
    ISO: 'fr',
  },
  {
    logo: 'KoreaFlagIcon',
    code: 'ko',
    betby: 'ko',
    IETFCode: 'ko',
    name: '한국어',
    ISO: 'ko',
  },

  {
    logo: 'IndoFlagIcon',
    code: 'id',
    betby: 'id',
    IETFCode: 'id',
    name: 'Bahasa',
    ISO: 'id', // or "in"
  },
  {
    logo: 'PortugalFlagIcon',
    code: 'pt-PT',
    betby: 'pt',
    IETFCode: 'pt-PT',
    name: 'Português',
    ISO: 'pt',
  },
  {
    logo: 'SpainFlagIcon',
    code: 'es',
    betby: 'es',
    IETFCode: 'es',
    name: 'Español',
    ISO: 'es',
  },
  {
    logo: 'VietnameseFlagIcon',
    code: 'vi',
    betby: 'vi',
    IETFCode: 'vi',
    name: 'Tiếng Việt',
    ISO: 'vi',
  },
  {
    logo: 'ThailandFlagIcon',
    code: 'th',
    betby: 'th',
    IETFCode: 'th',
    name: 'ภาษาไทย',
    ISO: 'th',
  },
  {
    logo: 'TurkeyFlagIcon',
    code: 'tr',
    betby: 'tr',
    IETFCode: 'tr',
    name: 'Türkçe',
    ISO: 'tr',
  },
  {
    logo: 'JapaneseFlagIcon',
    code: 'ja',
    betby: 'ja',
    IETFCode: 'ja',
    name: '日本語',
    ISO: 'ja',
  },
  {
    logo: 'RussianFlagIcon',
    code: 'ru',
    betby: 'ru',
    name: 'Русский',
    IETFCode: 'ru',
    ISO: 'ru',
  },
  {
    logo: 'ItalianFlagIcon',
    code: 'it',
    betby: 'it',
    name: 'Italiano',
    IETFCode: 'it',
    ISO: 'it',
  },
]
/*
  Follow this site: 
  https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
  for IOS
*/

// export const LANGUAGES_LOGO = {
//   en: 'EnglishFlagIcon,
//   ar: 'ArabFlagIcon,
//   'pt-BR': 'BrazilFlagIcon,
//   'zh-Hans': 'ChinaFlagIcon,
//   'zh-Hant': 'HongkongFlagIcon,
//   fr: 'FranceFlagIcon,
//   ko: 'KoreaFlagIcon,
//   id: 'IndoFlagIcon,
//   'pt-PT': 'PortugalFlagIcon,
//   es: 'SpainFlagIcon,
//   vi: 'VietnameseFlagIcon,
//   th: 'ThailandFlagIcon,
//   tr: 'TurkeyFlagIcon,
//   js: 'JapaneseFlagIcon,
// }
