import { RouteConfig } from './constants/route'
import { MetaTag } from './types/metatag'

export const getDefaultMeta = (t: (text: string) => string = (text) => text, lang?: string): MetaTag => {
  return {
    title: t('HunnyPlay: Top Crypto Casino and iGaming Destination'),
    description: t(
      'Best Crypto Gaming, Provably Fair Live Dealer, 10,000 Slot Games, unique-bonus, freespins, BTC, ETH, USDT, DeFi Tokens Supported, fast-withdrawals, and profitable-vault',
    ),
    ogImage: 'https://hunnyplay.io/hunnyplaybanner.png',
    canonical: `https://hunnyplay.io/${lang}`,
  }
}

export const DEFAULT_META: MetaTag = getDefaultMeta()

export const getCustomMeta = (
  path: RouteConfig,
  t: (text: string) => string = (text) => text,
  lang?: string,
  params?: string,
): MetaTag => {
  if (path.startsWith(RouteConfig.Blogs)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('News')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/news`,
    }
  }

  if (path.startsWith(RouteConfig.BonusCenter)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Bonus center')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/bonuses`,
    }
  }

  if (path.startsWith(RouteConfig.Promotions)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Promotions')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/promotions`,
    }
  }

  if (path.startsWith(RouteConfig.FavoriteGames)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Games')} | ${t('Favorites')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/games/favorites`,
    }
  }

  if (path.startsWith(RouteConfig.RecentGames)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Games')} | ${t('Recent')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/games/recent`,
    }
  }

  if (path.startsWith(RouteConfig.Games)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Games')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/games${params ? `/${params}` : ''}`,
    }
  }

  if (path.startsWith(RouteConfig.Cashback)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Cashback')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/cashback`,
    }
  }

  if (path.startsWith(RouteConfig.CashbackAbout)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Cashback information')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/cashback`,
    }
  }

  if (path.startsWith(RouteConfig.QueryDeposit)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Query deposit')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/query/deposit`,
    }
  }

  if (path.startsWith(RouteConfig.Sport)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Sport')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/sport`,
    }
  }

  if (path.startsWith(RouteConfig.Profile)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Profile')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/profile`,
    }
  }

  if (path.startsWith(RouteConfig.Stake)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('HUNNY & LOVE Staking')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/staking`,
    }
  }

  if (path.startsWith(RouteConfig.Referral)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Referral')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/referral`,
    }
  }

  if (path.startsWith(RouteConfig.HunnyJar)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Hunny Jar')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}`,
    }
  }

  if (path.startsWith(RouteConfig.TransactionHistory)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Transactions')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/profile`,
    }
  }

  if (path.startsWith(RouteConfig.VIPClub)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('VIP Club')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/vip-club`,
    }
  }

  if (path.startsWith(RouteConfig.DepositTransactionHistory)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Deposit Transactions')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/profile`,
    }
  }

  if (path.startsWith(RouteConfig.WithdrawTransactionHistory)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Withdraw Transactions')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/profile`,
    }
  }

  if (path.startsWith(RouteConfig.SwapTransactionHistory)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Swap Transactions')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/profile`,
    }
  }

  if (path.startsWith(RouteConfig.BonusTransactionHistory)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Bonus Transactions')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/profile`,
    }
  }

  if (path.startsWith(RouteConfig.RewardTransactionHistory)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Reward Transactions')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/profile`,
    }
  }
  if (path.startsWith(RouteConfig.TipTransactionHistory)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Tip Transactions')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/profile`,
    }
  }
  if (path.startsWith(RouteConfig.DailyCheckinHistory)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Daily Checkin History')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/profile`,
    }
  }
  if (path.startsWith(RouteConfig.ShortCutPage)) {
    return {
      ...getDefaultMeta(t, lang),
      title: `${t('Shortcut Page')} | ${t('HunnyPlay')}`,
      canonical: `https://hunnyplay.io/${lang}/shortcut-page`,
    }
  }

  return {
    ...getDefaultMeta(t, lang),
  }
}
